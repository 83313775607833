<template>
  <AntRow>
    <AntCol>
      <AntTable
        :columns="columns"
        :dataSource="dataSource"
        :scroll="{  x: 1500, y: 'calc(100vh - 166px)' }"
        @change="onTableChange"
        bordered
      >
        <template #filterDropdown="{ setSelectedKeys, selectedKeys, confirm, clearFilters, column }">
          <div style="padding: 8px">
            <AntInput
              ref="searchInputRef"
              :placeholder="`Search ${column.dataIndex}`"
              :value="selectedKeys[0] === 'hideAutoUser' ? '' : selectedKeys[0]"
              class="search-input"
              @change="e => setSelectedKeys(e.target.value ? [e.target.value] : [])"
              @pressEnter="onSearch(selectedKeys, confirm, column.dataIndex)"
            />
            <AntCheckbox
              class="search-checkbox"
              v-if="column.dataIndex === 'userId'"
              v-model:checked="isAutoUserHided"
              @change="e => onCheck(e, confirm, column.dataIndex)"
            >
              Filter out auto userId
            </AntCheckbox>
            <div class="search-btns">
              <AntButton
                type="primary"
                size="small"
                @click="onSearch(selectedKeys, confirm, column.dataIndex)"
              >
                <template #icon><SearchIcon /></template>
                Search
              </AntButton>
              <AntButton size="small" @click="onReset(clearFilters)">
                Reset
              </AntButton>
            </div>
          </div>
        </template>
        <template #filterIcon>
          <SearchIcon :style="{ color: '#108ee9'}" />
        </template>
        <template #filterRender="{ text, column }">
          <span v-if="searchText && searchedColumn === column.dataIndex">
            <template
              v-for="(fragment, i) in text
                .split(new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i'))"
            >
              <mark
                v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                class="highlight"
                :key="i"
              >
                {{ fragment }}
              </mark>
              <template v-else>{{ fragment }}</template>
            </template>
          </span>
          <template v-else>
            {{ text }}
          </template>
        </template>

        <template #isCreatedInAWC="{ text }">
          <CheckIcon class="check-icon" v-if="text" />
          <CloseIcon class="close-icon" v-else />
        </template>

        <template #betLimit="{ text }">
          <!-- <AntTextarea
            v-if="editableData[record.key]"
            v-model:value="editableData[record.key][col]"
            style="margin: -5px 0"
            :autoSize="{ minRows: 3, maxRows: 5 }"
          /> -->
          <div class="bet-limit">{{ text }}</div>
        </template>

        <template v-for="{ dataIndex, toolTip } in userSettingColumns" #[dataIndex]="{ record }" :key="dataIndex">
          <AntTooltip
            :title="toolTip"
            trigger="hover"
            placement="bottom"
          >
            <AntSwitch
              v-if="editableData[record.key]"
              v-model:checked="editableData[record.key][dataIndex]"
              :disabled="!editableData[record.key]"
            />
            <AntSwitch
              v-else
              v-model:checked="record[dataIndex]"
              :disabled="!editableData[record.key]"
            />
          </AntTooltip>
        </template>

        <template #operation="{ record }">
          <div class="editable-row-operations">
            <span v-if="editableData[record.key]">
              <a @click="onSave(record.key)">Save</a>
              <AntDivider type="vertical" />
              <a @click="onCancel(record.key)">Cancel</a>
            </span>
            <span v-else>
              <AntPopconfirm
                v-if="dataSource.length"
                title="Sure to delete this member in TSSW?"
                @confirm="onDelete(record.key)"
              >
                <a>Delete</a>
              </AntPopconfirm>
              <AntDivider type="vertical" />
              <a @click="onEdit(record.key)">Edit</a>
            </span>
          </div>
        </template>
      </AntTable>

    </AntCol>
  </AntRow>
</template>

<script>
import {
  ref,
  reactive,
  watch,
  computed,
  toRefs,
  onBeforeMount,
} from 'vue'

import {
  Row as AntRow,
  Col as AntCol,
  Table as AntTable,
  Input as AntInput,
  Button as AntButton,
  Switch as AntSwitch,
  Tooltip as AntTooltip,
  Checkbox as AntCheckbox,
  Divider as AntDivider,
  Popconfirm as AntPopconfirm,
  message as antMessage,
} from 'ant-design-vue'

import SearchIcon from '@ant-design/icons-vue/lib/icons/SearchOutlined'
import CheckIcon from '@ant-design/icons-vue/lib/icons/CheckOutlined'
import CloseIcon from '@ant-design/icons-vue/lib/icons/CloseOutlined'

import { getUsers, updateUser, deleteUser } from '@/api/tssw'
import { USER_SETTINGS_FORM_FIELDS } from '@/constants/tssw'

export default {
  name: 'MemberList',
  components: {
    AntRow,
    AntCol,
    AntTable,
    AntInput,
    AntButton,
    // AntTextarea: AntInput.TextArea,
    AntSwitch,
    AntTooltip,
    AntCheckbox,
    AntDivider,
    AntPopconfirm,
    SearchIcon,
    CheckIcon,
    CloseIcon,
  },
  props: {
    awcEnv: String,
    loginUserData: Object,
    requestResponseData: Object,
  },
  emits: ['changeLoginUserData'],
  setup(props, { emit }) {
    const searchState = reactive({
      searchText: '',
      searchedColumn: '',
    })

    const filteredInfo = ref({
      userId: ['hideAutoUser'],
    })

    const searchInputRef = ref()
    const isAutoUserHided = ref(true)

    const onTableChange = (pagination, filters) => { // 有輸入字串 + 按 search 會觸發
      filters.userId = Object.values(filters.userId)

      if (isAutoUserHided.value && !filters.userId.includes('hideAutoUser')) {
        filters.userId.push('hideAutoUser')
      }

      filteredInfo.value = filters
    }

    const onSearch = (selectedKeys, confirm, dataIndex) => { // 按 search 會觸發
      confirm()

      searchState.searchText = selectedKeys[0] // eslint-disable-line prefer-destructuring
      searchState.searchedColumn = dataIndex

      if (dataIndex === 'userId') {
        if (isAutoUserHided.value && !filteredInfo.value.userId.includes('hideAutoUser')) {
          filteredInfo.value.userId.push('hideAutoUser')
        } else {
          filteredInfo.value.userId.pop()
        }
      }
    }

    const onReset = (clearFilters) => {
      clearFilters()
      searchState.searchText = ''
    }

    const onCheck = e => isAutoUserHided.value = e.target.checked

    const userSettingColumns = USER_SETTINGS_FORM_FIELDS.slice(1).map(({ id, label, toolTip }) => ({
      title: label,
      dataIndex: id,
      toolTip,
      width: 90,
      align: 'center',
      slots: {
        customRender: id,
      },
    }))

    const editableFields = userSettingColumns.map(({ dataIndex }) => dataIndex)

    const columns = computed(() => {
      const filtered = filteredInfo.value

      return [
        {
          title: 'User ID',
          dataIndex: 'userId',
          width: 150,
          fixed: 'left',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'filterRender',
          },
          filteredValue: filtered.userId,
          onFilter: (value, record) => {
            const isAuto = record.userId.startsWith('auto') || record.userId.startsWith('devauto')

            // 沒有搜尋字串 + 有勾隱藏 auto
            if (filtered.userId[0] === 'hideAutoUser') return !isAuto

            let showRow = true
            if (filtered.userId.includes('hideAutoUser')) showRow = !isAuto

            // 有搜尋字串 + 有勾隱藏 auto / 有搜尋字串 + 沒勾隱藏 auto
            return record.userId.toLowerCase().includes(value.toLowerCase()) && showRow
          },
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                searchInputRef.value.focus()
              }, 100)
            }
          },
        },
        {
          title: 'Created in AWC',
          dataIndex: 'isCreatedInAWC',
          width: 80,
          align: 'center',
          slots: {
            customRender: 'isCreatedInAWC',
          },
        },
        {
          title: 'Balance',
          dataIndex: 'balance',
          width: '10%',
          slots: {
            customRender: 'balance',
          },
        },
        {
          title: 'Bet Limit',
          dataIndex: 'betLimit',
          width: '30%',
          slots: {
            customRender: 'betLimit',
          },
        },
        {
          title: 'Currency',
          dataIndex: 'currency',
          width: 90,
          align: 'center',
          slots: {
            filterDropdown: 'filterDropdown',
            filterIcon: 'filterIcon',
            customRender: 'filterRender',
          },
          onFilter: (value, record) => record.currency.toLowerCase().includes(value.toLowerCase()),
          onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
              setTimeout(() => {
                searchInputRef.value.focus()
              }, 100)
            }
          },
        },
        {
          title: 'Language',
          dataIndex: 'language',
          width: 90,
          align: 'center',
          slots: {
            customRender: 'language',
          },
        },
        ...userSettingColumns,
        {
          title: 'Operation',
          dataIndex: 'operation',
          width: 100,
          fixed: 'right',
          slots: {
            customRender: 'operation',
          },
        },
      ]
    })

    const dataSource = ref()
    const editableData = reactive({})

    const setDataSource = async (awcEnv) => {
      const { data: usersData } = await getUsers(awcEnv)
      dataSource.value = usersData.map(d => ({ key: d.userId, ...d }))
    }

    onBeforeMount(() => setDataSource(props.awcEnv))
    watch(() => props.awcEnv, newVal => setDataSource(newVal))

    const onDelete = async (key) => {
      const messageKey = `delete-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const { status, message } = await deleteUser({ userId: key, env: props.awcEnv })

      if (status === 'success') {
        delete editableData[key]
        dataSource.value = dataSource.value.filter(d => d.userId !== key)
        antMessage.success({
          key: messageKey,
          content: `Delete member: ${key} successfully`,
        })
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to delete member: ${message}`,
        })
      }
    }

    const onEdit = (editKey) => {
      const data = dataSource.value.find(d => editKey === d.key)
      editableData[editKey] = Object.keys(data).reduce((acc, key) => {
        if (editableFields.includes(key)) acc[key] = data[key]
        return acc
      }, {})
    }

    const onSave = async (key) => {
      const messageKey = `update-${Date.now()}`
      antMessage.loading('', { key: messageKey })

      const { status, data: updateUserData, message } = await updateUser({
        userId: key,
        env: props.awcEnv,
        ...editableData[key],
      })

      if (status === 'success') {
        Object.assign(dataSource.value.find(d => key === d.key), editableData[key])
        delete editableData[key]

        antMessage.success({
          key: messageKey,
          content: `Update member: ${key} successfully`,
        })

        if (updateUserData.userId === props.loginUserData.userId) {
          emit('changeLoginUserData', updateUserData)
        }
      } else {
        antMessage.error({
          key: messageKey,
          duration: 5,
          content: `Failed to update member: ${message}`,
        })
      }
    }

    const onCancel = (key) => {
      delete editableData[key]
    }

    return {
      searchState,
      ...toRefs(searchState),
      searchInputRef,
      isAutoUserHided,
      onTableChange,
      onSearch,
      onCheck,
      onReset,
      dataSource,
      columns,
      editableData,
      userSettingColumns,
      onDelete,
      onEdit,
      onSave,
      onCancel,
    }
  },
}
</script>

<style scoped>
.bet-limit {
  letter-spacing: 0.5px;
  max-height: 80px;
  overflow: auto;
}

.check-icon {
  color: #52c41a;
}

.close-icon {
  color: #ff4d4f;
}

.search-input {
  display: block;
  width: 188px;
  margin-bottom: 8px;
}

.search-checkbox {
  margin-bottom: 8px;
}

.search-btns button {
  width: 90px;
}

.search-btns button:first-child {
  margin-right: 8px;
}
</style>
